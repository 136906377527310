:root {
    --primary-lighten-7: #d9e5ec !important; // 60%
    --primary-lighten-6: #b6cddc !important;
    --primary-lighten-5: #6092b2 !important;
    --primary-lighten-4: #5186a8 !important;
    --primary-lighten-3: #497897 !important;
    --primary-lighten-2: #416a85 !important;
    --primary-lighten-1: #385d74 !important;
    --primary: #304F63 !important;
    --primary-darken-4: #284152 !important;
    --primary-darken-3: #1f3441 !important;
    --primary-darken-2: #17262f !important;
    --primary-darken-1: #0f181e !important;
}